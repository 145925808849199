import React from "react";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";

// import required modules
import { Navigation } from "swiper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const Services = () => {
  return (
    <div className="services_inner">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        navigation={{
          prevEl: "#prev_btn",
          nextEl: "#next_btn",
        }}
        modules={[Navigation]}
        className="mySwiper"
      >
        {services.map(({ text, imageSrc }, idx) => (
          <SwiperSlide key={idx}>
            <div className="single_service">
              <p>{text}</p>
              <img src={imageSrc} alt="image" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="d-flex align-items-center justify-content-between service_bottom">
        <a
          href="/documents/prices.pdf"
          target="_blank"
          rel="noopener noreferrer"
          role="button"
          className="page_btn"
        >
          See All Services
        </a>
        <div className="next_prev_icons">
          <LeftOutlined id="prev_btn" />
          <RightOutlined id="next_btn" />
        </div>
      </div>
    </div>
  );
};
export default Services;

const services = [
  { text: "Manucure", imageSrc: "/img/landing_img/service-1.jpg" },
  { text: "Pedicure", imageSrc: "/img/landing_img/pedicure.jpg" },
  { text: "Gel Color", imageSrc: "/img/landing_img/gel-color.jpg" },
  { text: "Waxing", imageSrc: "/img/landing_img/waxing.jpg" },
  { text: "Facial", imageSrc: "/img/landing_img/facial.jpg" },
  { text: "Full Set", imageSrc: "/img/landing_img/full-set.jpg" },
];
