import React, { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";

// import required modules
import { Navigation } from "swiper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Rate } from "antd";

const Testimonial = () => {
  return (
    <div className="testimonials" id="testmonials">
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h2 className="section_title">What Our Client Say</h2>
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              navigation={{
                prevEl: "#prev_btn",
                nextEl: "#next_btn",
              }}
              modules={[Navigation]}
              className="mySwiper"
            >
              {testimonials.map(({ text, name, imageSrc, value }, idx) => (
                <SwiperSlide className="single_testimonial" key={idx}>
                  <div className="review_text_box">
                    <p>{text}</p>
                    <div className="quota" />
                  </div>
                  <div className="user_identity justify-content-end justify-content-lg-center">
                    <img src={imageSrc} alt="image" />
                    <div>
                      <p className="name">{name}</p>
                      <Rate
                        className="user-rating"
                        disabled
                        defaultValue={value}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="next_prev_icons">
              <div className="next_prev_icons_inner">
                <LeftOutlined id="prev_btn" />
                <RightOutlined id="next_btn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testimonial;

const testimonials = [
  {
    text: "This place is great! I take my girlfriend here and she loves it. Kim takes great care of her nails and is very friendly. She's a big knowledgeable Seahawks fan too I like talking to her, if the Seahawks play in primetime Monday night or Thursday night she probably closes early so be aware. 😉",
    imageSrc: "/img/landing_img/william.png",
    name: "William Self",
    value: 5,
  },
  {
    name: "tanisha rosado",
    text: "The lady was so nice and friendly. It was a very comfortable environment and I love the way my nails came out she took her time and did an amazing job.She has become one of my good friends and I believe all her customers feel that way about her as she is super personable. Truly pleased!!!!",
    imageSrc: "/img/landing_img/tanisa.png",
    value: 5,
  },
  {
    name: "Michelle Davis",
    text: "Love my nail expert she has been my nail lady for over 15 years when I leave town I don't get pedicures until I get back to town because I don't let anyone else mess with my hands or feet no one else comes close to her professionalism💙",
    imageSrc: "/img/landing_img/davis.png",
    value: 5,
  },
  {
    name: "Erika Neill",
    text: "Experienced staff, but slow if anyone else is there.",
    imageSrc: "/img/landing_img/erika.png",
    value: 4,
  },
];
