import React from "react";

const Header = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav className="navbar navbar-expand-lg navbar-light">
            <a className="navbar-brand" href="#">
              <img src="/img/logo_lts.png"/>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active me-3">
                  <a href="#about" className="nav-link">
                    About Us
                  </a>
                </li>
                <li className="nav-item active me-3">
                  <a href="#services" className="nav-link">
                    Services
                  </a>
                </li>
                <li className="nav-item active me-3">
                  <a href="/documents/prices.pdf" target="_blank" rel="noopener noreferrer" className="nav-link">
                    Prices
                  </a>
                </li>
                <li className="nav-item active me-3">
                  <a href="#testmonials" className="nav-link">
                    Reviews
                  </a>
                </li>
                <li className="nav-item active">
                  <a href="#get_touch" className="nav-link">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
