import React from "react";

const GetTouch = () => {
  return (
    <div className="get_touch" id="get_touch">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="contact-img">
              <img src="/img/landing_img/get-touch.jpg" />
            </div>
          </div>
          <div className="col-md-3 col-lg-4 d-none d-lg-block"/>
          <div className="col-md-6 col-lg-4">
            <div className="section_title">
              <h2>Get in touch and find out how we can help</h2>
            </div>
            <div className="input_form">
              <a href="tel:(253) 582-6189">Call (253) 582-6189</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetTouch;
