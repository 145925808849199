import { BulbOutlined } from "@ant-design/icons";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Footer, GetTouch, Header, Services, Testimonial } from "../components";

const LandingPage = () => {
  const [navScroll, setNavScroll] = useState(false);

  const changeNavBack = () => {
    if (window.scrollY >= 100) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };
  window.addEventListener("scroll", changeNavBack);

  return (
    <div className="landing_page">
      <div className={`landing_header ${navScroll ? "navScroll" : ""}`}>
        <Header />
      </div>
      <div className="container" id="about">
        <div className="row">
          <div className="col-md-12">
            <div className="hero_outter">
              <h2>Your Fashion and Style Start Here</h2>
              <p>
                Welcome to Oakbrook Nails Salon! We are a full-service nail
                salon located in the heart of Lakewood. Our team of highly
                trained and experienced nail technicians are dedicated to
                providing you with the highest level of service and attention to
                detail.
              </p>
              <p>
                Our salon is designed to be a relaxing and rejuvenating escape
                from the stresses of everyday life. We offer a comfortable and
                clean environment where you can sit back, relax, and enjoy a
                pampering experience.
              </p>
              <p>
                We look forward to serving you and helping you achieve the
                perfect look for your nails. Thank you for choosing Oakbrook
                Nails Salon!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="features" id="features">
        <div className="container">
          <div className="row">
            {featureServices.map(({ text }, idx) => (
              <Features text={text} key={idx} />
            ))}
          </div>
        </div>
      </div>
      <div className="services" id="services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section_title">See Our Featured Services</h2>
              <Services />
            </div>
          </div>
        </div>
      </div>
      <div className="clear_nail">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="img_box">
                <img
                  src="/img/slide-1.jpg"
                  alt=""
                  style={{ visibility: "hidden" }}
                />
                <img src="/img/about-1.jpg" alt="" className="first_image" />
                <img src="/img/about-2.jpg" alt="" className="second_image" />
              </div>
            </div>
            <div className="col-md-5">
              <div style={{ display: "table", height: "100%" }}>
                <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                  <h2 className="section_title">
                    Get the flawless and Clear Nail
                  </h2>
                  <p>
                    From classic manicures and pedicures to gel nails, we have
                    something for every style and preference. Whether you're
                    looking to relax and unwind or glam up your look, we have
                    something for everyone at our salon
                  </p>
                  <a
                    href="tel:(253) 582-6189"
                    role="button"
                    className="page_btn"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
      <GetTouch />
      <Footer />
    </div>
  );
};

export default LandingPage;

const Features = ({ Icon, text }) => (
  <div className="col-md-3 col-6 mb-3">
    <div className="single_box">
      <div className="icon">
        <BulbOutlined />
      </div>
      <p>{text}</p>
    </div>
  </div>
);

const featureServices = [
  { text: "Mani Pedi" },
  { text: "Get Polish" },
  { text: "Manicure" },
  { text: "Full Set" },
];
